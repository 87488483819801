import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';
import AutoSubmit from '@stimulus-components/auto-submit';

const application = Application.start();
// Manually load plugins
application.register('auto-submit', AutoSubmit);
// Automatically load all filed ending with _controllers.js
const controllers = import.meta.glob('./**/*_controller.js', { eager: true });
registerControllers(application, controllers);

// NOTE BELOW CODE DOESN'T WORK, BUT IT'S HERE AS A REFERENCE
// When registering controllers from an external folder, HMR doesn't work anymore
// So the workaround would be to let the fullReload module hard refresh on a change, but that would be annoying
// For now keeping all the controllers in the controller file, but we need to find a better solution
//
// This could work with https://github.com/thecodingbrew/stimulus-vite-helpers-2 but that's a forked version
// const controllers = import.meta.glob(['./**/*_controller.js', '../../components/**/*_controller.js'], { eager: true });
// registerControllers(application, controllers);
//
// registerControllers doesn't work loading controllers from an external folder
// This script checks for all _controller.js files in the components folder and subfolder
// const component_controllers = import.meta.glob('../../components/**/*_controller.js', { eager: true });
// And then for each found file it will register it as FileController with the name `file`
// for (const path in component_controllers) {
//   const controllerName = path.split('/').pop().replace('_controller.js', '');
//   application.register(controllerName, component_controllers[path].default);
// }
