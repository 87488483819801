import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger', 'menu'];
  connect() {
    document.addEventListener('click', this.hide.bind(this));
  }

  toggle(event) {
    event.preventDefault();
    this.element.classList.toggle('show');
    // Also toggle aria-expanded
    const expanded = this.element.classList.contains('show');
    this.triggerTargets.forEach((element) => element.setAttribute('aria-expanded', expanded));
  }

  // When clicking outside the dropdown, hide it.
  hide(event) {
    if (!this.element.contains(event.target)) {
      this.close();
    }
  }

  close(_e) {
    this.element.classList.remove('show');
  }

  triggerTargetConnected(element) {
    // Add aria-expanded="false" to the trigger element
    element.setAttribute('aria-expanded', 'false');
  }

  disconnect() {
    document.removeEventListener('click', this.hide.bind(this));
  }
}
