import { Controller } from '@hotwired/stimulus';
import Choices from 'choices.js';
import { isObject } from '../helpers/functions';

export default class extends Controller {
  static targets = ['select', 'options']

  initialize () {
    this.element['choices'] = this
    this.refresh = this.refresh.bind(this)
    this.add = this.add.bind(this)
    this.remove = this.remove.bind(this)
    this.search = this.search.bind(this)
    this.load = this.load.bind(this)
    this.update = this.update.bind(this)
    this.options = this.options.bind(this)
    this.optionsReducer = this.optionsReducer.bind(this)
    this.searchPath = this.element.dataset.searchPath
    this.forceOption = this.element.dataset.forceOption || true
  }

  connect () {
    setTimeout(this.setup.bind(this), 0)
  }

  setup () {
    this.choices = new Choices(this.selectTarget, this.options())
    this.input = this.element.querySelector('.choices__input--cloned')
    if (this.searchPath) {
      this.refresh()
      this.input.addEventListener('input', this.search)
      this.selectTarget.addEventListener('showDropdown', this.load)
      this.selectTarget.addEventListener('addItem', this.add)
      this.selectTarget.addEventListener('removeItem', this.remove)
      if (!this.selectTarget.multiple) {
        this.selectTarget.addEventListener('hideDropdown', this.checkSelection);
      }
    }
  }

  disconnect () {
    if (this.searchPath) {
      this.input.removeEventListener('input', this.search)
      this.selectTarget.removeEventListener('change', this.refresh)
      this.selectTarget.removeEventListener('showDropdown', this.load)
      this.selectTarget.removeEventListener('addItem', this.add)
      this.selectTarget.removeEventListener('removeItem', this.remove)
      if (!this.selectTarget.multiple) {
        this.selectTarget.removeEventListener('hideDropdown', this.checkSelection)
      }
    }
    try {
      this.choices.destroy()
    } catch {}
    this.choices = undefined
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = null;
      }
  }

  refresh () {
    this.choices.setChoices([], 'value', 'label', true)
    if (this.hasOptionsTarget) {
      ;[...this.optionsTarget.children].forEach(this.append.bind(this))
    }
  }

  append (option) {
    if (
      ![...this.selectTarget.options].some(o => {
        return o.value === option.value
      })
    )
      this.choices.setChoices([option], 'value', 'label', false)
  }

  load () {
    this.search()
  }

  add (event) {
    if (this.hasOptionsTarget) {
      const option = [...this.optionsTarget.children].find(option => {
        return option.value === event.detail.value
      })
      if (option) {
        option.setAttribute('selected', '')
      } else {
        const newOption = document.createElement('option')
        newOption.setAttribute('label', event.detail.label)
        newOption.setAttribute('value', event.detail.value)
        newOption.setAttribute('selected', '')
        this.optionsTarget.appendChild(newOption)
      }
    }
  }

  checkSelection = () => {
    const choicesList = document.querySelector(".choices__list--single");

    // Clear active items if no choices are selected (single select)
    if (!choicesList || choicesList.children.length === 0) {
      this.choices.removeActiveItems();
      this.choices.clearStore();
    }
  };

  remove (event) {
    if (this.hasOptionsTarget) {
      const option = [...this.optionsTarget.children].find(item => {
        return item.value === event.detail.value
      })
      if (option)
        this.searchPath ? option.remove() : option.removeAttribute('selected')
      this.choices.removeChoice(event.detail.value)
    }
    if (this.forceOption && !this.selectTarget.options.length)
      this.selectTarget.add(document.createElement('option'))
  }

  search(event) {
    let query = event ? event.target.value : '';
    const url = new URL(this.searchPath, window.location.origin);
    // it's needed to set query correctly if several parameters are provided
    url.searchParams.set('q', query);
    this.debounceFetchData(url.toString()).then(this.update);
  }

  fetchData = async (url) => {
    try {
      const response = await fetch(url, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      });
      return await response.json();
    } catch (err) {
      return [];
    }
  };

  update (data) {
    const stringified_data = data.map(dat => {
      return {
        ...dat,
        value: String(dat.value)
      };
    });
    this.choices.setChoices(this.filteredData(stringified_data), 'value', 'label', true)
  }

  filteredData (data) {
    let selectedValues = []
    if(isObject(this.choices.getValue())){
      selectedValues = [this.choices.getValue().value];
    }else{
      // looks like selectedValues work in a different way for async single selects, so set data directly
      selectedValues = this.choices.getValue()?.map(choice => choice.value) || data
    }
    return data.filter(item => !selectedValues.includes(String(item.value)));
  }

  options () {
    return 'silent renderChoiceLimit maxItemCount addItems removeItems removeItemIconText removeItemButton editItems duplicateItemsAllowed delimiter paste searchEnabled searchChoices searchFloor searchResultLimit position resetScrollPosition addItemFilter shouldSort shouldSortItems placeholder placeholderValue prependValue appendValue renderSelectedChoices loadingText noResultsText noChoicesText itemSelectText addItemText maxItemText'
      .split(' ')
      .reduce(this.optionsReducer, {})
  }

  optionsReducer (accumulator, currentValue) {
    if (this.element.dataset[currentValue] !== undefined) {
      if (/true/i.test(this.element.dataset[currentValue])) {
        // Convert to boolean if true
        accumulator[currentValue] = true
      } else if (/false/i.test(this.element.dataset[currentValue])) {
        // Convert to boolean if false
        accumulator[currentValue] = false
      } else if (!isNaN(this.element.dataset[currentValue]) && this.element.dataset[currentValue] !== '') {
        // Convert to integer if number
        accumulator[currentValue] = parseInt(this.element.dataset[currentValue])
      } else{
        // Keep it a string
        accumulator[currentValue] = this.element.dataset[currentValue]
      }
    }
    return accumulator
  }

  debounceFetchData = (query = '', delay = 300) => {
    return new Promise((resolve) => {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(async () => {
        const result = await this.fetchData(query);
        resolve(result);
      }, delay);
    });
  };
}
