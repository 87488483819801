import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.showPreview = this.showPreview.bind(this);
    this.hidePreview = this.hidePreview.bind(this);

    this.element.addEventListener('mouseenter', this.showPreview);
    this.element.addEventListener('mouseleave', this.hidePreview);
  }

  disconnect() {
    this.element.removeEventListener('mouseenter', this.showPreview);
    this.element.removeEventListener('mouseleave', this.hidePreview);
  }

  showPreview() {
    const image = this.element;
    const previewImage = document.createElement('img');

    previewImage.classList.add('preview-image');
    previewImage.src = image.dataset.previewUrl;
    previewImage.alt = 'Uploaded image preview';
    previewImage.style.pointerEvents = 'none';

    document.body.appendChild(previewImage);

    image.hoverImage = previewImage;
  }

  hidePreview() {
    const image = this.element;
    if (image.hoverImage) {
      document.body.removeChild(image.hoverImage);
      image.hoverImage = null;

      image.removeEventListener('mousemove', image.hoverImage?.movePreview);
    }
  }
}
