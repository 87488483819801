import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['focus'];

  focusTargetConnected(element) {
    // Throw a warning if there are more than 1 focus targets
    if (this.focusTargets.length > 1) {
      console.warn('There are more than 1 focus targets, this may cause unexpected behavior');
    }
    // Select all text and focus element
    element.select();
  }
}
